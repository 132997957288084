import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AboutUs, ContactUs, Department, Finishing, Home, Projects } from './Pages';
// import { AboutUs, ContactUs, Department, Finishing, Home, Projects } from "./Pages"

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/About-Us' element={<AboutUs />} />
          <Route path='/ContactUs' element={<ContactUs />} />
          <Route path='/Department' element={<Department />} />
          <Route path='/Finishing' element={<Finishing />} />
          <Route path='/Projects' element={<Projects />} />
          {/* <Route path='/' element={<Home />} />

          <Route path='/Department' element={<Department />} />
          <Route path='/Finishing' element={<Finishing />} />
          <Route path='/Projects' element={<Projects />} />
          <Route path='/ContactUs' element={<ContactUs />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
