import "./OurClients.css"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import axios from "axios"
const OurClients = () => {
    const { t, i18n } = useTranslation();
    const [logos, setLogos] = useState()
    useEffect(() => {
        axios.get("https://api.trendsbymidas.mom/api/clients/all", {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {
                setLogos(res.data.data.clients)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    return (
        <>
            <div className={`${i18n.language == "ar" ? "container-fluid our-clients our-clients-ar" : "container-fluid our-clients"} `}>
                <h2 className={i18n.language == "ar" ? "h2-ar" : "h2-en"}>{t("OUR-CLIENT")}</h2>
                <div className="row row-cols-4 row-cols-lg-4 row-cols-xl-6">
                    {logos && logos.map(item => (
                        <div className="col col-logos">
                            <img src={item.logo} />
                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}

export default OurClients