import "./Header.css"
import Logo from "../../Assets/Images/Vector.png"
import smLogo from "../../Assets/Images/Trends.png"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import i18next from "i18next";
import { useTranslation } from "react-i18next"
import ArLogoHome from "../../Assets/Images/trends-01.png"
import ArLogo from "../../Assets/Images/trends-02.png"
const Header = (props) => {
    let media = window.matchMedia("(max-width:767px)")
    let ipad = window.matchMedia("(min-device-width : 768px) and (max-device-width : 1023px)")
    const [isVisible, setIsVisible] = useState(false)
    const [isAr, setIsAr] = useState(window.localStorage.getItem("i18nextLng") == "ar" ? true : false)
    const { t, i18n } = useTranslation();
    useEffect(() => {
        window.localStorage.setItem("i18nextLng", isAr ? "ar" : "en")
        i18next.changeLanguage(window.localStorage.getItem("i18nextLng"))
        document.body.dir = i18n.language == "ar" ? "rtl" : "ltr"
    }, [isAr])
    const handleChangeLanguage = () => {
        window.localStorage.setItem("i18nextLng", isAr ? "ar" : "en")
        setIsAr(!isAr)

    }
    return (
        <>
            <header className={i18n.language == "ar" ? "container-fluid nav-trends nav-trends-ar" : "container-fluid nav-trends"} >
                <div className="logo-nav">
                    {props.color == "white" && media.matches == false && ipad.matches == false ?
                        <Link to="/">
                            {i18n.language == "ar" ? <img src={ArLogo} /> : <svg xmlns="http://www.w3.org/2000/svg" width="215" height="291" viewBox="0 0 215 291" fill="none">
                                <path d="M107.19 214.38C166.389 214.38 214.38 166.389 214.38 107.19C214.38 47.9906 166.389 0 107.19 0C47.9906 0 0 47.9906 0 107.19C0 166.389 47.9906 214.38 107.19 214.38Z" fill="#B31B1B" />
                                <path d="M143.48 62.9L70.97 79.4C69.4 86.24 68.89 93.73 70.89 104.24L95.08 98.74L95.21 151.49C105.9 151.12 113.09 148.95 119.41 145.91L119.29 93.23L143.38 87.75C144.95 80.91 145.46 73.42 143.46 62.91L143.48 62.9Z" fill="white" />
                                <path d="M63.3598 263.45H58.5798V243.56H53.5898V238.68H68.3498V243.56H63.3598V263.45Z" fill="white" />
                                <path d="M81.0502 252.7C81.7902 253.91 82.3402 254.83 82.6802 255.49L86.9902 263.45H81.1002L76.2902 253.76H75.9802V263.45H71.2002V238.68C72.6802 238.53 74.8402 238.5 77.7002 238.5C82.9402 238.5 85.5302 240.96 85.5302 245.84C85.5302 249.36 83.5002 251.97 81.0302 252.7H81.0502ZM80.5602 246.32C80.5602 244.23 79.4802 243.2 77.3602 243.2C76.8402 243.2 76.3702 243.24 76.0002 243.27V249.62C76.3402 249.66 76.7402 249.69 77.2002 249.69C79.1102 249.69 80.5602 248.52 80.5602 246.31V246.32Z" fill="white" />
                                <path d="M89.5303 238.68H101.21V243.49H94.3103V248.41H100.87V253.07H94.3103V258.54H101.21V263.46H89.5303V238.68Z" fill="white" />
                                <path d="M105.13 263.45V238.68H111.45L116.63 250.46C117.31 252 117.89 253.65 118.42 255.41C118.27 252.95 118.17 249.39 118.17 244.84V238.68H122.79V263.45H116.5L111.11 251.27C110.37 249.47 109.85 247.86 109.51 246.5C109.66 249.36 109.7 252.81 109.7 256.88V263.45H105.13Z" fill="white" />
                                <path d="M127.07 263.42V238.76C129.26 238.61 131.6 238.54 134.16 238.54C141.09 238.54 144.54 242.54 144.54 250.57C144.54 254.72 143.52 257.98 141.43 260.29C139.43 262.53 136.68 263.63 133.23 263.63C131.66 263.63 129.62 263.56 127.07 263.41V263.42ZM133.85 243.35C132.86 243.35 132.19 243.39 131.85 243.42V258.57C132.4 258.68 132.99 258.72 133.64 258.72C137.43 258.72 139.4 255.64 139.4 251.01C139.4 245.98 137.92 243.34 133.85 243.34V243.35Z" fill="white" />
                                <path d="M155.39 238.24C156.75 238.24 158.01 238.39 159.18 238.72L158.75 243.71C157.58 243.23 156.35 242.98 155.14 242.98C153.23 242.98 152.3 243.75 152.3 245.33C152.3 246.17 152.73 246.87 153.63 247.46L156.53 248.96C159.33 250.43 160.78 252.67 160.78 255.6C160.78 257.98 159.98 259.97 158.47 261.51C156.93 263.09 154.96 263.9 152.52 263.9C150.67 263.9 149.01 263.68 147.56 263.24L148.02 257.81C149.53 258.54 151.07 258.91 152.64 258.91C154.52 258.91 155.47 258.07 155.47 256.34C155.47 255.42 155.04 254.69 154.18 254.1L151.28 252.71C148.51 251.35 147.15 249.11 147.15 246C147.15 240.79 150.76 238.26 155.38 238.26L155.39 238.24Z" fill="white" />
                                <path d="M76.6901 286.05C76.6901 287.27 76.3401 288.27 75.6501 289.09C74.7901 290.09 73.5101 290.6 71.8201 290.6L68.6201 290.53V275.57C70.4401 275.53 71.6701 275.5 72.2901 275.5C74.9701 275.5 76.3101 276.7 76.3101 279.09C76.3101 280.84 75.5801 281.99 74.1501 282.57V282.61C75.6001 282.83 76.7001 284.14 76.7001 286.04L76.6901 286.05ZM70.3301 277.16V282.01C70.5701 282.03 70.9401 282.03 71.4701 282.03C73.5001 282.03 74.5201 281.17 74.5201 279.42C74.5201 277.89 73.6801 277.12 72.0101 277.12C71.5401 277.12 70.9901 277.14 70.3301 277.16ZM74.8901 286.16C74.8901 284.43 73.7501 283.57 71.5001 283.57C70.9201 283.57 70.5301 283.57 70.3301 283.59V288.84C70.8901 288.93 71.4301 288.95 71.9301 288.95C73.6201 288.95 74.8901 288.06 74.8901 286.16Z" fill="white" />
                                <path d="M82.0901 290.52V285.14L78.1601 275.57H80.0401L82.0301 280.62C82.3801 281.51 82.7001 282.37 82.9801 283.21H83.0001C83.2001 282.61 83.5601 281.66 84.0801 280.35L86.0201 275.56H87.8101L83.8101 285.13V290.51H82.1001L82.0901 290.52Z" fill="white" />
                                <path d="M105.15 281.86C104.96 280.31 104.83 279.05 104.76 278.07H104.72C104.48 279.2 104.27 280.15 104.07 280.88L101.39 290.52H99.5701L97.0201 281.5C96.6701 280.28 96.4101 279.13 96.2201 278.07H96.1801C96.1201 279.22 96.0101 280.53 95.8501 282.04L94.9901 290.53H93.2401L95.0801 275.57H97.1601L99.8201 285.01C100.01 285.65 100.25 286.65 100.55 287.98H100.57C100.79 286.92 101.04 285.96 101.26 285.14L103.98 275.57H106.1L108 290.53H106.21L105.15 281.87V281.86Z" fill="white" />
                                <path d="M110.82 275.57H112.53V290.53H110.82V275.57Z" fill="white" />
                                <path d="M116.05 290.52V275.59L119.81 275.5C124.09 275.5 126.23 277.96 126.23 282.88C126.23 285.38 125.58 287.29 124.28 288.66C123.03 289.97 121.36 290.61 119.24 290.61C118.59 290.61 116.67 290.52 116.06 290.52H116.05ZM124.42 283.01C124.42 279.11 122.84 277.16 119.64 277.16C119.32 277.16 118.69 277.18 117.76 277.23V288.75C118.23 288.84 118.77 288.88 119.42 288.88C122.6 288.88 124.43 286.78 124.43 283.01H124.42Z" fill="white" />
                                <path d="M129.86 287.02L128.82 290.52H127.07L131.63 275.56H133.57L138.17 290.52H136.25L135.21 287.02H129.86ZM132.54 277.76H132.5C132.33 278.49 132.15 279.16 131.98 279.75L130.29 285.4H134.76L133.08 279.79C132.89 279.17 132.71 278.5 132.54 277.75V277.76Z" fill="white" />
                                <path d="M143.96 275.37C144.8 275.37 145.56 275.46 146.23 275.66L146.03 277.45C145.3 277.18 144.58 277.05 143.87 277.05C142.19 277.05 141.34 277.83 141.34 279.38C141.34 280.09 141.64 280.66 142.23 281.13C142.49 281.33 143.14 281.71 144.2 282.22C145.11 282.66 145.78 283.08 146.19 283.55C146.79 284.21 147.08 285.08 147.08 286.14C147.08 287.49 146.65 288.6 145.8 289.44C144.94 290.3 143.83 290.72 142.43 290.72C141.43 290.72 140.49 290.56 139.68 290.3L139.88 288.35C140.74 288.75 141.61 288.95 142.52 288.95C144.06 288.95 145.24 288.15 145.24 286.4C145.24 285.67 144.94 285.05 144.35 284.58C144.09 284.38 143.44 284.03 142.4 283.52C141.51 283.08 140.86 282.66 140.46 282.21C139.86 281.54 139.57 280.66 139.57 279.6C139.57 276.81 141.51 275.37 143.96 275.37Z" fill="white" />
                            </svg>}</Link> : props.color == "white" || media.matches == true || ipad.matches == true ? <Link to="/"><img src={smLogo} /> </Link> :
                            <Link to="/"><img src={i18n.language == "ar" ? ArLogoHome : Logo} /> </Link>
                    }

                </div>
                <ul className={props.color}>
                    <li className={isAr ? "en" : "ar"}>
                        <Link to="/about-us">
                            {t("about")}
                        </Link>
                    </li>
                    <li className={isAr ? "en" : "ar"}>
                        <Link to="/Department">
                            {t("Department")}
                        </Link>
                    </li>
                    <li className={isAr ? "en" : "ar"}>
                        <Link to="/Finishing">
                            {t("MATERIALS")}
                        </Link></li>
                    <li className={isAr ? "en" : "ar"}>
                        <Link to="/Projects">
                            {t("PROJECTS")}
                        </Link>
                    </li>
                    <li className={isAr ? "en" : "ar"}>
                        <Link to="/ContactUS">
                            {t("CONTACT")}
                        </Link>
                    </li>
                </ul>
                {ipad.matches == true ? <></> : <div className={i18n.language == "ar" ? "translator-ar" : "translator"}>
                    <p onClick={() => handleChangeLanguage()} className="language">{i18n.language == "en" ? "عربي" : "English"}</p>
                </div>}

                <div className="burger" onClick={() => setIsVisible(!isVisible)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
                        <path d="M0 19.2V17.3784H24V19.2H0ZM0 10.5108V8.68918H24V10.5108H0ZM0 1.82163V0H24V1.82163H0Z" fill="#B31B1B" />
                    </svg>
                </div>
                <div className="sm-con" style={{
                    height: isVisible ? "100vh" : "0vh",
                    paddingTop: isVisible ? "54px" : "0px"
                }}>

                    <ul className="sm-nav" style={{
                        display: isVisible ? "flex" : "none"
                    }}>
                        <li className={isAr ? "en" : "ar"}>
                            <Link to="/about-us">
                                {t("about")}
                            </Link>
                        </li>
                        <li className={isAr ? "en" : "ar"}>
                            <Link to="/Department">
                                {t("Department")}
                            </Link>
                        </li>
                        <li className={isAr ? "en" : "ar"}>
                            <Link to="/Finishing">
                                {t("MATERIALS")}
                            </Link></li>
                        <li className={isAr ? "en" : "ar"}>
                            <Link to="/Projects">
                                {t("PROJECTS")}
                            </Link>
                        </li>
                        <li className={isAr ? "en" : "ar"}>
                            <Link to="/ContactUS">
                                {t("CONTACT")}
                            </Link>
                        </li>

                        <li onClick={() => handleChangeLanguage()} className="language">
                            {i18n.language == "en" ? "عربي" : "English"}  </li>
                    </ul>
                </div>
            </header>

        </>
    )
}

export default Header