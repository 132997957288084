import "./Home.css"
import { Header, Hero } from "../../Sections"

const Home = () => {
    return (
        <>
            <Header color="white" />
            <Hero page="home" />


        </>
    )
}

export default Home