import "./AboutUs.css"
import { AboutHero, OurClients, Footer, Header } from "../../Sections"



const AboutUs = () => {
    return (
        <>
            <Header />
            <AboutHero />
            <OurClients />
            <Footer />



        </>
    )
}

export default AboutUs