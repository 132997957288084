import "./Footer.css"

const Footer = () => {
    return (
        <>
            <div className="container-fluid footer">
                <p>© Copyright 2024 - trends by midas</p>
            </div>
        </>
    )
}

export default Footer