import "./Hero.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';
import { useTranslation } from "react-i18next"
import Img1 from "../../Assets/Images/Elements-01.png"
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { motion, useAnimation } from "framer-motion"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"

const Hero = (props) => {
    const { t, i18n } = useTranslation();
    const animation = useAnimation()
    const [initial, setInitial] = useState({
        x: -500,
        opacity: 0
    })
    const [initial2, setInitial2] = useState({
        x: 500,
        opacity: 0
    })
    const navigate = useNavigate()
    useEffect(() => {
        animation.start({
            x: 0,
            opacity: [0, 0.5, 1],
            transition: {
                duration: .8
            }
        })
    }, [initial, initial2])
    return (
        <>
            <div id='hero' className={`${i18n.language == "ar" ? "container-fluid hero px-0 m-0 ar-hero" : "container-fluid hero px-0 m-0"} ` + " " + props.page}>
                <Swiper
                    dir={i18n == "ar" ? "rtl" : "ltr"}
                    key={i18n.language}
                    spaceBetween={30}
                    effect={'fade'}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    modules={[EffectFade, Navigation, Autoplay]}
                    className="mySwiper">
                    <SwiperSlide>
                        {({ isActive }) => (
                            <div className="img-slide-one">
                                <div className="flex-edit-row">
                                    <img src={Img1} />
                                    <motion.h2
                                        initial={initial}
                                        animate={{
                                            x: isActive ? 0 : -500,
                                            opacity: isActive ? [0, 0.5, 1] : 0,
                                            transition: {
                                                duration: .8
                                            }
                                        }}
                                        onClick={() => navigate("/projects")}
                                    >
                                        {t("over")} 500 {t("projects")}
                                        <span className={i18n.language == "ar" ? "arr-ar" : "arr-en"}>
                                            {i18n.language == "ar" ? <IoIosArrowBack /> : <IoIosArrowForward size={40} />}
                                        </span>
                                    </motion.h2>

                                </div>
                                <motion.div
                                    initial={initial2}
                                    animate={{
                                        x: isActive ? 0 : 500,
                                        opacity: isActive ? [0, 0.5, 1] : 0,
                                        transition: {
                                            duration: .8
                                        }
                                    }}
                                    className="driv-by">
                                    <h5 className="m-0">{t("DRIVEN-BY")} <span>{t("SOLUTIONS")}</span></h5>
                                </motion.div>
                            </div>
                        )}

                    </SwiperSlide>
                    <SwiperSlide>
                        {({ isActive }) => (
                            <div className="img-slide-two">
                                <div className="flex-edit-row">
                                    <img src={Img1} />
                                    <motion.h2
                                        initial={{
                                            x: -500,
                                            opacity: 0
                                        }}
                                        animate={{
                                            x: isActive ? 0 : -500,
                                            opacity: isActive ? [0, 0.5, 1] : 0,
                                            transition: {
                                                duration: .8
                                            }
                                        }}
                                        onClick={() => navigate("/Finishing")}
                                    >
                                        {t("Acoustic-Panels")}
                                        <span className={i18n.language == "ar" ? "arr-ar" : "arr-en"}>
                                            {i18n.language == "ar" ? <IoIosArrowBack /> : <IoIosArrowForward size={40} />}
                                        </span>
                                    </motion.h2>

                                </div>
                                <motion.div
                                    initial={initial2}
                                    animate={{
                                        x: isActive ? 0 : 500,
                                        opacity: isActive ? [0, 0.5, 1] : 0,
                                        transition: {
                                            duration: .8
                                        }
                                    }}
                                    className="driv-by">
                                    <h5 className="m-0">{t("DRIVEN-BY")} <span>{t("SOLUTIONS")}</span></h5>
                                </motion.div>
                            </div>
                        )}

                    </SwiperSlide>
                    <SwiperSlide>
                        {({ isActive }) => (
                            <div className="img-slide-three">
                                <div className="flex-edit-row">
                                    <img src={Img1} />
                                    <motion.h2
                                        initial={{
                                            x: -500,
                                            opacity: 0
                                        }}
                                        animate={{
                                            x: isActive ? 0 : -500,
                                            opacity: isActive ? [0, 0.5, 1] : 0,
                                            transition: {
                                                duration: .8
                                            }
                                        }}
                                        onClick={() => navigate("/Finishing")}
                                    >
                                        {t("Sports-Flooring")}
                                        <span className={i18n.language == "ar" ? "arr-ar" : "arr-en"}>
                                            {i18n.language == "ar" ? <IoIosArrowBack /> : <IoIosArrowForward size={40} />}
                                        </span>
                                    </motion.h2>

                                </div>
                                <motion.div
                                    initial={initial2}
                                    animate={{
                                        x: isActive ? 0 : 500,
                                        opacity: isActive ? [0, 0.5, 1] : 0,
                                        transition: {
                                            duration: .8
                                        }
                                    }}
                                    className="driv-by">
                                    <h5 className="m-0">{t("DRIVEN-BY")} <span>{t("SOLUTIONS")}</span></h5>
                                </motion.div>
                            </div>
                        )}

                    </SwiperSlide>
                    <SwiperSlide>
                        {({ isActive }) => (
                            <div className="img-slide-four">
                                <div className="flex-edit-row">
                                    <img src={Img1} />
                                    <motion.h2
                                        initial={{
                                            x: -500,
                                            opacity: 0
                                        }}
                                        animate={{
                                            x: isActive ? 0 : -500,
                                            opacity: isActive ? [0, 0.5, 1] : 0,
                                            transition: {
                                                duration: .8
                                            }
                                        }}
                                        onClick={() => navigate("/Finishing")}
                                    >
                                        {t("Walls-Protection")}
                                        <span className={i18n.language == "ar" ? "arr-ar" : "arr-en"}>
                                            {i18n.language == "ar" ? <IoIosArrowBack /> : <IoIosArrowForward size={40} />}
                                        </span>
                                    </motion.h2>

                                </div>
                                <motion.div
                                    initial={initial2}
                                    animate={{
                                        x: isActive ? 0 : 500,
                                        opacity: isActive ? [0, 0.5, 1] : 0,
                                        transition: {
                                            duration: .8
                                        }
                                    }}
                                    className="driv-by">
                                    <h5 className="m-0">{t("DRIVEN-BY")} <span>{t("SOLUTIONS")}</span></h5>
                                </motion.div>
                            </div>
                        )}

                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}

export default Hero