import "./Finishing.css"
import { Header, Footer } from "../../Sections"
import Img from "../../Assets/Images/57.png"
import Dots from "../../Assets/Images/Layer_2.png"
import Img2 from "../../Assets/Images/Rectangle 58.png"
import Img3 from "../../Assets/Images/Rectangle 60.png"
import Img4 from "../../Assets/Images/Rectangle 61.png"
import { useTranslation } from "react-i18next"
const Finishing = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <Header />
            <div className={i18n.language == "ar" ? `container-fluid department department-ar Finishing` : `container-fluid department Finishing`}>
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-6">
                        <div className="images">
                            <img src={Img} className="archi" />
                            <img src={Dots} className="dots" />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <div className={i18n.language == "ar" ? "text text-ar" : "text"}>
                            <h2>{t("Cladding")}</h2>
                            <p>{t("Handrail")}<br />
                                {t("Walls-Protection")}</p>

                        </div>
                    </div>
                </div>
            </div>
            <div className={i18n.language == "ar" ? `container-fluid department department-ar Finishing` : `container-fluid department Finishing`}>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 order-2">
                        <div className="images images-2">
                            <img src={Img2} className="archi" />
                            <img src={Dots} className="dots dots-2" />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 order-1">
                        <div className={i18n.language == "ar" ? "text text-2 text-ar" : "text text-2"}>
                            <h2>{t("Flooring")}</h2>
                            <p>{t("Vinyl-Raised")}<br />
                                {t("Floor-Carpet")}<br />
                                {t("Rubber-Flooring")}<br />
                                {t("Wood-Flooring")}<br />
                                {t("Sports-Flooring")}<br />
                                {t("Entrance-Matt")}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={i18n.language == "ar" ? `container-fluid department department-ar Finishing` : `container-fluid department Finishing`}>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <div className="images">
                            <img src={Img3} className="archi" />
                            <img src={Dots} className="dots" />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <div className={i18n.language == "ar" ? "text text-ar" : "text"}>
                            <h2>{t("Acoustic-Panels")}</h2>
                            <p>{t("Acoustic-Wooden")} <br />
                                {t("Acoustic-Fabric")}<br />
                                {t("Linear-Wooden")}<br />
                                {t("Micro-Perforated")}<br />
                                {t("Ceiling-Tiles")}<br />
                                {t("Movable-Partitions")}<br />
                                {t("Acoustic-Carpet")}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={i18n.language == "ar" ? `container-fluid department department-ar Finishing last-department` : `container-fluid department Finishing last-department`}>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 order-2">
                        <div className="images images-2">
                            <img src={Img4} className="archi" />
                            <img src={Dots} className="dots dots-2" />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 order-1">
                        <div className={i18n.language == "ar" ? "text text-ar text-2" : "text text-2"}>
                            <h2>{t("Cubicles&Partitions")}</h2>
                            <p>{t("Toilet-Cubicles")} <br />
                                {t("Demountable-Partitions")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Finishing